import React from "react"
import { Link } from "gatsby"

import { IconContext } from "react-icons"
import { FaEnvelope, FaPhoneAlt, FaFacebookSquare, FaInstagram, FaTwitter } from "react-icons/fa"

import footerStyles from "../styles/components/footer.module.css"

const Footer = () => (
  <footer>
    <div className={ footerStyles.ourLocation }>
      <h5>Where We Are</h5>
      <p><strong>TrueNorth ArtHouse</strong></p>
      <p>The IMC Block, 4th Floor</p>
      <p>141 Scout Rallos Extension</p>
      <p>Sacred Heart, Quezon City, Metro Manila 1103 PH</p>
      <div className={ footerStyles.email }>
        <IconContext.Provider value={{ size: "1em", color: "#612d8c" }}>
          <FaEnvelope />
        </IconContext.Provider>
        <p>info@truenortharthouse.com.ph</p>
      </div>
      <div className={ footerStyles.trunkLine }>
        <IconContext.Provider value={{ size: "1em", color: "#612d8c" }}>
          <FaPhoneAlt />
        </IconContext.Provider>
        <p>(02) 8426-4844</p>
      </div>
    </div>
    <div className={ footerStyles.footerNav }>
      <h5>Helpful Links</h5>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about/">About</Link></li>
        <li><Link to="/services/">Services</Link></li>
        <li><Link to="/news/">News</Link></li>
        <li><Link to="/contact/">Contact Us</Link></li>
      </ul>
    </div>
    <div className={ footerStyles.socialMedia }>
      <h5>Follow Us</h5>
      <ul>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#612d8c" }}>
            <FaFacebookSquare />
          </IconContext.Provider>
          <a href="https://www.facebook.com/truenortharthouse/">Facebook</a>
        </li>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#612d8c" }}>
            <FaTwitter />
          </IconContext.Provider>
          <a href="https://twitter.com/TNArtHouse">Twitter</a>
        </li>
        <li>
          <IconContext.Provider value={{ size: "1.25em", color: "#612d8c" }}>
            <FaInstagram />
          </IconContext.Provider>
          <a href="https://www.instagram.com/truenortharthouse/">Instagram</a>
        </li>
      </ul>
    </div>
    <p className={ footerStyles.copyrightText }>
      Copyright © 2020, TrueNorth ArtHouse. All Rights Reserved.
    </p>
  </footer>
)

export default Footer